import serverSideApi from '@/src/lib/dynamicYield/serverSideApi';
import axios from 'axios';
import { getCookies } from '@/src/Utils/Cookies';

export const getDyData = (data: any[], pageType: string, ctx: any, dyPageData: string) => {
  return async (dispatch: any) => {
    let dyData: any[] = [];

    for (const item of data) {
      const dyResponse = await serverSideApi(ctx, {
        page: {
          type: pageType,
          location: pageType,
          data: [dyPageData],
        },
        selector: {
          names: [item?.selectorName],
        },
      });

      if (dyResponse?.data && Object.keys(dyResponse.data).length > 0) {
        const dyDataWithSelector = {
          ...dyResponse,
          selectorName: item?.selectorName,
        };

        dyData?.push(dyDataWithSelector);

        dispatch({ type: 'GET_DY_DATA', payload: [...dyData] });
      }
    }
  };
};

export const getCategoryDyData = (data: any[], pageType: string, ctx: any, dyPageData: string) => {
  return async (dispatch: any) => {
    const dyData: { [key: string]: any[] } = {};

    for (const item of data) {
      const dyResponse = await serverSideApi(ctx, {
        page: {
          type: pageType,
          location: pageType,
          data: [dyPageData],
        },
        selector: {
          names: [item?.selectorName],
        },
      });

      const dyDataWithSelector = {
        ...dyResponse,
        selectorName: item?.selectorName,
      };

      const floorPosition = dyDataWithSelector.data?.custom?.floorPosition;
      if (floorPosition !== undefined) {
        if (!dyData[floorPosition]) {
          dyData[floorPosition] = [];
        }
        dyData[floorPosition].push(dyDataWithSelector);
      } else {
        console.warn('Missing floorPosition in item:', dyDataWithSelector);
      }
    }

    dispatch({ type: 'GET_CATEGORY_DY_DATA', payload: dyData });
  };
};

export async function trackSlotClick(slotId: string) {
  const apiURL: any = process.env.NEXT_PUBLIC_DYNAMIC_YIELD_CLIENT_ENGAGEMENT_URL;

  const apiKey: any = process.env.NEXT_PUBLIC_DYNAMIC_YIELD_CLIENT_API_KEY;

  const { _dyid, _dyid_server, _dyjsession } = getCookies();

  try {
    await axios.post(
      apiURL,

      {
        user: {
          active_consent_accepted: false,
          dyid: _dyid,
          dyid_server: _dyid_server,
        },
        session: {
          dy: _dyjsession,
        },
        context: {
          channel: 'WEB',
        },
        engagements: [
          {
            type: 'SLOT_CLICK',
            slotId: slotId,
          },
        ],
      },
      {
        headers: {
          'dy-api-key': apiKey,
        },
      },
    );
  } catch (error) {
    console.log(error);
  }
}

export async function trackClick(decisionId: string, variationId: string) {
  const apiURL: any = process.env.NEXT_PUBLIC_DYNAMIC_YIELD_CLIENT_ENGAGEMENT_URL;

  const apiKey: any = process.env.NEXT_PUBLIC_DYNAMIC_YIELD_CLIENT_API_KEY;

  const { _dyid, _dyid_server, _dyjsession } = getCookies();

  try {
    await axios.post(
      apiURL,

      {
        user: {
          active_consent_accepted: false,
          dyid: _dyid,
          dyid_server: _dyid_server,
        },
        session: {
          dy: _dyjsession,
        },
        context: {
          channel: 'WEB',
        },
        engagements: [
          {
            type: 'CLICK',
            slotId: decisionId,
            variations: [variationId],
          },
        ],
      },
      {
        headers: {
          'dy-api-key': apiKey,
        },
      },
    );
  } catch (error) {
    console.log(error);
  }
}
